import Axios from 'axios'
import ElementUI from 'element-ui'
import Storage from '@/utils/storage'
import Store from '@/store'
// import Router from '@/router'

let requestConfig = {
	method: 'GET',
	baseURL: '/api/',
	timeout: 5000,
	headers: {token: null}
}

const successCall = (res, params) => {
	if (parseInt(res.data.code) != 0 && res.data.code !== "success") {
		ElementUI.Message.error(res.data.msg)
		typeof params.failure === 'function' && params.failure(res)
		typeof params.always === 'function' && params.always(res)
		if (parseInt(res.data.code) == 401) {
			Storage.clear()
			// Router.app.$router.push('/')
		}
	} else {
		typeof params.success === 'function' && params.success(res)
		typeof params.always === 'function' && params.always(res)
	}
}

const failureCall = (res, params) => {
	typeof params.error === 'function' && params.error(res)
	typeof params.always === 'function' && params.always(res)
	ElementUI.Message.error(res)
}

let Request = (config, params) =>{
	config = Object.assign({}, requestConfig, config)
	params = params || {}

	if (Store.getters.userToken)
		config.headers.token = Store.getters.userToken.token

	return new Promise((resolve, reject) => {
		if (config.method.toLowerCase() == 'delete') {
			Axios.delete(config.url, config).then(res => {
				successCall(res, params)
				resolve(res)
			}).catch(res => {
				failureCall(res, params)
				reject(res)
			})
		} else {
			Axios(config).then(function(res){
				successCall(res, params)
				resolve(res)
			}).catch(function(res){
				failureCall(res, params)
				reject(res)
			})
		}
	})
}

export default {
	request(config, params) {
		return Request(config, params)
	},

	upload(config, params){
		config = Object.assign({}, requestConfig, config)
		if (Store.getters.userToken){
			config.headers.token = Store.getters.userToken.token
		}
		return new Promise((resolve, reject) => {
			let formData = new FormData()
			for (let k in config.data){
				formData.append(k, config.data[k])
			}
			Axios.post(requestConfig.baseURL+config.url, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					'token': config.headers.token
				}
			}).then(function(res){
				successCall(res, params)
				resolve(res)
			}).catch(function(res){
				failureCall(res, params)
				reject(res)
			})
		})
	}
}
